export let modifierKey = '';
export let miscKey = '';

export function shortcutSymbol() {
	if (isMac(navigator.platform)) {
		modifierKey = '⌘'; // command key
		miscKey = '⌥'; // option key
		return;
	}
	modifierKey = 'Ctrl';
	miscKey = 'Alt';
}

function isMac(text: string) {
	if (
		text.startsWith('Mac') ||
		text.startsWith('iPhone') ||
		text.startsWith('iPad') ||
		text.startsWith('iPod')
	) {
		return true;
	}
	return false;
}
